<template>
  <div>
    <div class="skintype_result_inner">
      <img
        v-for="image in image_sources"
        :key="image.enum"
        :src="image"
      >
    </div>
    <section class="dermatologist_inCarge_container">
      <div class="dermatologist_inCarge_inner">
        <h2>担当医</h2>
        <img
          :src="
            ROOT_IMAGE_URL
              + '/face-icon/hamano.png'
          "
        >
        <h3>濱野英明</h3>
        <div class="dermatologist_profile">
          <p>テティス横濱美容皮膚科&nbsp;&nbsp;院長</p>
          <p>日本スキンケア協会・皮膚科顧問</p>
          <p>2015ミス・ユニバース&nbsp;ビューティーキャンプ講師</p>
        </div>
        <div class="profile_btn_container">
          <router-link
            :to="{name: 'Concierges', params: {'incomingGenre': 'skintype'}}"
            class="profile_btn"
          >
            プロフィールの詳細はこちら
          </router-link>
        </div>
      </div>
    </section>
    <section class="guiding_section_container">
      <div class="guiding_section_inner">
        <img
          src="@/assets/logos/guiding_arrow.svg"
          class="guiding_arrow"
        >
        <div class="guiding_section_toChatbot">
          <p>
            次にあなたの肌質に合った化粧品を探してみましょう
          </p>
          <router-link
            to="/flow/item-suggestions"
          >
            コンシェルジュからおすすめ商品の提案を受ける
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'SkinTypeResult',
  props:{
    skin_type_id: {
      type: String,
      default: '0'
    },
    id_token: {
      type: String,
      default: ''
    },
    result_set: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      image_sources: [],
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT
    }
  },
  mounted(){
    const target_url = process.env.VUE_APP_RESULT_URL+'skin-type-diag';
    axios.post(target_url, {
      liff_id: process.env.VUE_APP_LIFF_ID,
      command: 'get_result_images',
      skin_type_id: this.skin_type_id,
      id_token: this.id_token,
      result_set: this.result_set
    })
      .then((res) => {
        if(res.data.is_first_diag){
          this.$gtag.event('tutorial_complete')
        }
        const image_url_base = process.env.VUE_APP_BASE_URL+`flow-images/skin-type-diag/result-images/${res.data.resultNumber}/${res.data.resultNumber}_`;
        this.image_sources = Array.from(Array(res.data.image_count), (v, k)=>k+1).map(x=> image_url_base+x+'.png');
      })
      .catch((err) => {
        console.log('SkinTypeResult',err.code, err.message);
      })
  }
};
</script>
<style scoped>
.skintype_result_inner {
  padding: 0 40px 60px 40px;
  position: relative;
}
.dermatologist_inCarge_container {
  background-color: #F5EAE7;
}
.dermatologist_inCarge_inner {
  padding: 70px 40px 61px 40px;
}
h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
  margin: 0;
}
h2 {
    font-size: 22px;
}
h3 {
  font-size: 20px;
}
.skintype_result_inner {
  padding: 0 40px 60px 40px;
  position: relative;
}
.dermatologist_profile {
  padding: 10px 0 20px 0;
}
.profile_btn_container {
  text-align: center;
}
p {
  font-size: 14px;
}
.profile_btn {
  color: #707070;
  background-color: #FADAD2;
  padding: 11px 21px;
  font-size: 14px;
  display: inline-block;
}
.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #F5EAE7;
}
.guiding_arrow{
  width: 26px;
  padding-bottom: 40px;
}
.dermatologist_inCarge_inner img {
  padding: 10px 0;
}
img {
  width: 100%;
  object-fit: cover;
  border: #F29F8F;
}
img, embed, object, iframe {
  vertical-align: bottom;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
 background-color: transparent;
  text-decoration: none;
  color: inherit; 
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444444;
  background-color: #fadad2;
  font-weight: bold;
}
.guiding_section_toChatbot {
  padding-bottom: 50px;
}
.guiding_section_toChatbot p {
  display: block;
  padding-bottom: 20px;
  font-size: 16px;
}
/* .guiding_section_toMypage p {
  display: block;
  padding-bottom: 20px;
  font-size: 16px;
}
.guiding_section_toMypage a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444444;
  background-color: #fadad2;
  font-weight: bold;
} */
</style>
